import Footer from './components/Footer/Footer';
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import { useEffect, useState } from "react";

const EXPIRED_DATE = '2024-07-27T23:59:59';
const FORM_API_URL = 'https://httpbin.org/post';

function App() {
    const [stepState, setStepState] = useState('start');

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
        });
    }, [stepState]);

    useEffect(() => {
        isExpiredDate();
    }, []);

    const isExpiredDate = () => {
        const now = Date.now();

        if (Date.parse(EXPIRED_DATE) < now) {
            setStepState('closed');
        }
    }

  return (
    <div className="App">
        <Header />
        <Main
            stepState={stepState}
            setStepState={setStepState}
            apiUrl={FORM_API_URL}
            expiredDate={EXPIRED_DATE}
        />
        <Footer
            stepState={stepState}
        />
    </div>
  );
}

export default App;
