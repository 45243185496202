/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubmissionsForm = /* GraphQL */ `
  mutation CreateSubmissionsForm(
    $input: CreateSubmissionsFormInput!
    $condition: ModelSubmissionsFormConditionInput
  ) {
    createSubmissionsForm(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      postcode
      age_field
      message
      mediafile
      terms_and_conditions
      privacy_policy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubmissionsForm = /* GraphQL */ `
  mutation UpdateSubmissionsForm(
    $input: UpdateSubmissionsFormInput!
    $condition: ModelSubmissionsFormConditionInput
  ) {
    updateSubmissionsForm(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      postcode
      age_field
      message
      mediafile
      terms_and_conditions
      privacy_policy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubmissionsForm = /* GraphQL */ `
  mutation DeleteSubmissionsForm(
    $input: DeleteSubmissionsFormInput!
    $condition: ModelSubmissionsFormConditionInput
  ) {
    deleteSubmissionsForm(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      postcode
      age_field
      message
      mediafile
      terms_and_conditions
      privacy_policy
      createdAt
      updatedAt
      __typename
    }
  }
`;
