import React from 'react';
import PromoBlock from '../Promo/PromoBlock';
import FormBlock from '../FormBlock/FormBlock';
import SuccessBlock from '../Success/SuccessBlock';
import ClosedBlock from '../Closed/ClosedBlock';
import clip from '../../img/video/ALDICore_video.mp4';

const Main = (props) => {
    const { stepState, setStepState, apiUrl, expiredDate } = props;

    const scrollToBlock = (e) => {
        e.preventDefault();
        const block = document.getElementById('entering_section');

        if (block) {
            window.scrollTo({
                left: 0,
                top: block.offsetTop,
                behavior: 'smooth',
            });
        }
    }

    const banner = stepState === 'closed' ? (
        <div className={stepState + ' banner'} />
    ) : null;

    const videoBlock = stepState === 'start' ? (<div className="video-banner">
        <video className="video" autoPlay="autoplay" playsInline="playsinline" loop={true} muted={true}>
            <source src={clip} type='video/mp4' />
        </video>
        <div className="video-container">
            <div className="video-title">
                <h2>Show us how</h2>
                <h1>#ALDIcore</h1>
                <h2>you are</h2>
            </div>
            <button className="btn" onClick={(e) => scrollToBlock(e)}>Enter competition</button>
        </div>
    </div>) : null;

    const promoBlock = stepState === 'start' ? (
        <PromoBlock />
    ) : null;

    const formBlock =  stepState === 'start' ? (
        <FormBlock setStepState={setStepState} apiUrl={apiUrl} />
    ) : null;

    const successBlock = stepState === 'success' ? (
        <SuccessBlock expiredDate={expiredDate} />
    ) : null;

    const closedBlock = stepState === 'closed' ? (
        <ClosedBlock />
    ) : null;

    return (
        <>
            {banner}
            {videoBlock}
            {promoBlock}
            {formBlock}
            {successBlock}
            {closedBlock}
        </>
    );
};

export default Main;
