import React from 'react';
import styles from './header.module.css';
import Logo from '../../img/logo.svg';

const Header = () => {
    return (
        <header className={styles.root}>
            <a href="https://www.aldi.com.au/" target="_blank" rel="noreferrer" className={styles.link} >
                <img src={Logo} alt="Logo" />
            </a>
        </header>
    );
};

export default Header;
