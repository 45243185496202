import React from 'react';
import styles from '../Main/main.module.css';
import Facebook from "../../img/logo-facebook-2.svg";
import Instagram from "../../img/logo-instagram.svg";
import Tiktok from "../../img/logo-tiktok.svg";
import CopyBlock from "../CopyBlock/CopyBlock";


const PromoBlock = () => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <h2>Your chance to win 3 years’ <br/>worth of FREE groceries!</h2>
                <p className="sub-title">There’s also 5 runner-up prizes of 1 year’s worth of groceries up for grabs. <br/>That’s a big ALDI haul.</p>
            </div>
            <div className="blue">
                <div className="container">
                    <h3>Embrace your #ALDIcore to win.</h3>
                    <p>Are you a connoisseur of Choceur? A Knopper-can’t-stopper? A frequent peruser of the middle
                        aisle and a conqueror of the checkout? You might have a specific ALDI recipe that you share
                        with all your mates, a Special Buy that you think is extra special, a product you can’t
                        live without, or some Good Different tips, tricks and hacks that you think everyone should know about.</p>
                    <p>Find a way to share your ALDI love, your Good Different vibe or your general ALDI-prowess.
                        Go hardcore. Go ALDIcore. Write a song. Make a sign. Show us your Good Different energy.
                        Then tell us about it.</p>
                    <div className={styles.promoCards}>
                        <div className={styles.promoCard}>
                            <h4>#ALDIcore</h4>
                        </div>
                        <div className={styles.promoCard}>
                            <h4>#ALDIcore</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" id="entering_section">
                <div className="block">
                    <h3>Entering is easy.</h3>
                    <p>Just tell us or show us how you ‘ALDI’. The best entry will win 3 years’ worth of FREE groceries to the value of $300 per week for 3 years - that’s 156 weeks of free groceries totalling $46,800 in ALDI Gift Cards! That’s a lot of Spanakopita.
                    </p>
                    <p>You can enter on social by posting on TikTok, Instagram or Facebook with the hashtag #ALDIcore and tag ALDI Australia (@aldiaustralia for Instagram; @aldi.australia on Facebook and TikTok). If entering on Facebook or Instagram make sure your entry is a feed post, not a story, and your profile is set to public to ensure it doesn’t get missed.
                    </p>
                </div>
            </div>
            <div className="block center container-sm">
                <div className={styles.socialBlock}>
                    <h3>Show us how #ALDIcore you are.</h3>
                    <div className={styles.logos}>
                        <a href="https://www.tiktok.com/@aldi.australia" target="_blank" rel="noreferrer">
                            <span className={styles.logo}><img src={Tiktok} alt="TikTok"/></span>
                            <span>TikTok</span>
                        </a>
                        <a href="https://www.instagram.com/aldiaustralia/?hl=en" target="_blank" rel="noreferrer">
                            <span className={styles.logo}><img src={Instagram} className={styles.instagram} alt="Instagram"/></span>
                            <span>Instagram</span>
                        </a>
                        <a href="https://www.facebook.com/ALDI.Australia" target="_blank" rel="noreferrer">
                            <span className={styles.logo}><img src={Facebook} alt="Facebook"/></span>
                            <span>Facebook</span>
                        </a>
                    </div>
                    <CopyBlock />
                </div>
            </div>
        </div>
    );
};

export default PromoBlock;
