import React from 'react';
import styles from '../Main/main.module.css';

const ClosedBlock = () => {
    return (
        <div className={styles.closed}>
            <div className="container">
                <h1>The party’s over.</h1>
                <h3>This competition has closed.</h3>
                <div className="block">
                    <p>Thanks for dropping by. Unfortunately the competition has ended.</p>
                    <p>You can see the full list of winners at <a href="https://www.aldi.com.au/competitions" target="_blank" rel="noreferrer" className="link">aldi.com.au/competitions</a>.</p>
                </div>
            </div>
        </div>
    );
};

export default ClosedBlock;
