import React from 'react';
import styles from './footer.module.css';
import Facebook from '../../img/logo-facebook.svg';
import Instagram from '../../img/logo-instagram-white.svg';
import Linkedin from '../../img/logo-linkedin.svg';
import X from '../../img/logo-x.svg';
import Up from "../../img/up.svg";

const Footer = (props) => {
    const { stepState } = props;

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <footer>
            <div className="container-xl">
                <div className={styles.root}>
                    <div className="block container center">
                        {stepState !== 'closed' &&
                        <h3>How #ALDIcore are you?</h3>
                        }
                        {stepState !== 'closed' &&
                            <p>Open to Australian residents 18+ (excluding NT & TAS). Ends: 11:59pm AEST on 27/7/24. Max 1 prize p/person. Prize value determined using research from <a href="https://www.canstarblue.com.au/groceries/average-grocery-bill/" target="_blank" rel="noreferrer" className="link">Canstar</a> and <a href="https://www.finder.com.au/budgeting/average-grocery-bill" target="_blank" rel="noreferrer" className="link">Finder</a> and is based on a weekly spend of $300 for a family of 4.</p>
                        }
                        <a href="https://www.aldi.com.au/about-aldi/aldi-competitions/aldicore-competition-terms-conditions/" target="_blank" rel="noreferrer" className="link terms">View Terms and Conditions</a>
                    </div>
                </div>
                <div className={styles.root}>
                    <ul className={styles.menu}>
                        <li><span className={styles.copyright}>
                        <a href="https://www.aldi.com.au/" target="_blank" rel="noreferrer">© 2024 by ALDI</a></span></li>
                        <li><a href="https://www.aldi.com.au/privacy-policy/" target="_blank" rel="noreferrer">Privacy</a></li>
                        <li><a href="https://www.aldi.com.au/about-aldi/aldi-competitions/aldicore-competition-terms-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a></li>
                    </ul>
                    <ul className={styles.social}>
                        <li><a href="https://twitter.com/aldiaustralia" target="_blank" rel="noreferrer">
                            <img src={X} alt="X" />
                        </a></li>
                        <li><a href="https://www.facebook.com/ALDI.Australia" target="_blank" rel="noreferrer">
                            <img src={Facebook} alt="Facebook" />
                        </a></li>
                        <li><a href="https://www.instagram.com/aldiaustralia/?hl=en" target="_blank"  rel="noreferrer">
                            <img src={Instagram} alt="Instagram" />
                        </a></li>
                        <li><a href="https://www.linkedin.com/company/aldi-stores-australia/?originalSubdomain=au"
                               target="_blank"
                               rel="noreferrer">
                            <img src={Linkedin} alt="Linkedin" />
                        </a></li>
                    </ul>
                    {stepState !== 'closed' &&
                    <button className={styles.scrollButton} onClick={(e) => scrollToTop(e)}>
                        <span><img src={Up} alt="Back to Top"/></span>
                        <span>Back to Top</span>
                    </button>
                    }
                </div>
            </div>
        </footer>
    );
};

export default Footer;
