import React, {useEffect} from 'react';
import styles from '../Main/main.module.css';
import Countdown from 'react-countdown';
import Facebook from "../../img/logo-facebook-2.svg";
import Instagram from "../../img/logo-instagram.svg";
import Tiktok from "../../img/logo-tiktok.svg";
import CopyBlock from "../CopyBlock/CopyBlock";

const scriptContent2 = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments)}
    gtag('js', new Date());
    gtag('config', 'DC-6356725');`;
const scriptContent3 = `
    gtag('event', 'conversion', {
    'allow_custom_scripts': true,
    'u1': '[Homepage]',
    'u2': '[OrderValue]',
    'u3': '[OrderID]',
    'u4': '[OrderQuantity]',
    'u5': '[URL]',
    'send_to': 'DC-6356725/aldic0/aldic0+standard'
    });`;

const tag = `<!-- Google tag (gtag.js) Competition Entrant Global Snippet -->`;


const SuccessBlock = (props) => {
    const { expiredDate } = props;

    useEffect(() => {
        addEventSnippet();
        window.fbq('trackCustom', 'CompetitionEntrant')
        window.gtag('event','submit_entry')
    }, []);

    const addEventSnippet = () => {
        const head = document.head;
        if (head.textContent.indexOf(tag) === -1) {
            const script1 = document.createElement('script');
            const script2 = document.createElement('script');
            const script3 = document.createElement('script');
            const script4 = document.createElement('noscript');
            const img = document.createElement('img');
            script1.setAttribute('async', '');
            script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=DC-6356725');
            script2.innerHTML = scriptContent2;
            script3.innerHTML = scriptContent3;
            // eslint-disable-next-line
            img.setAttribute('src', 'https://ad.doubleclick.net/ddm/activity/src=6356725;type=aldic0;cat=aldic0;u1=[Homepage];u2=[OrderValue];u3=[OrderID];u4=[OrderQuantity];u5=[URL];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?');
            img.setAttribute('width', '1');
            img.setAttribute('height', '1');
            img.setAttribute('alt', '');
            script4.append(img);

            head.append(tag);
            head.append(script1);
            head.append(script2);
            head.append(script3);
            head.append(script4);
        }
    }

    return (
        <div className="container-sm">
            <div className={styles.success}>
                <h3>You’re in the running!</h3>
                <p>We’re looking forward to seeing what you sent us. We’ll announce the lucky winners at <a href="https://www.aldi.com.au/about-aldi/aldi-competitions/" className="link" target="_blank" rel="noreferrer">aldi.com.au/competitions</a></p>
                <Countdown
                    date={expiredDate}
                    intervalDelay={0}
                    precision={3}
                    renderer={props => <div className={styles.countdown}>
                        <h4>Competition closes in...</h4>
                        <div className={styles.countRow}>
                                <span>
                                    <span className={styles.title}>Days</span>
                                    {props.formatted.days}
                                </span>
                            <span>:</span>
                            <span>
                                    <span className={styles.title}>Hours</span>
                                {props.formatted.hours}
                                </span>
                            <span>:</span>
                            <span>
                                    <span className={styles.title}>Minutes</span>
                                {props.formatted.minutes}
                                </span>
                            <span>:</span>
                            <span>
                                    <span className={styles.title}>Seconds</span>
                                {props.formatted.seconds}
                                </span>
                        </div>
                    </div>}
                />

                <div className="block center">
                    <div className={styles.socialBlock}>
                        <h3>Thank you!</h3>
                        <p className={styles.socialText}>Share that you’ve entered with friends and family by posting on TikTok, Instagram or Facebook with the hashtag <br/><strong>#ALDIcore</strong> and tag <strong>ALDI Australia</strong>.</p>
                        <div className={styles.promoCards}>
                            <div className={styles.promoCard}>
                                <h4>#ALDIcore</h4>
                            </div>
                        </div>
                        <div className={styles.logos}>
                            <a href="https://www.tiktok.com/@aldi.australia" target="_blank" rel="noreferrer">
                                <span className={styles.logo}><img src={Tiktok} alt="TikTok"/></span>
                                <span>TikTok</span>
                            </a>
                            <a href="https://www.instagram.com/aldiaustralia/?hl=en" target="_blank" rel="noreferrer">
                                <span className={styles.logo}><img src={Instagram} className={styles.instagram} alt="Instagram"/></span>
                                <span>Instagram</span>
                            </a>
                            <a href="https://www.facebook.com/ALDI.Australia" target="_blank" rel="noreferrer">
                                <span className={styles.logo}><img src={Facebook} alt="Facebook"/></span>
                                <span>Facebook</span>
                            </a>
                        </div>
                        <CopyBlock />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessBlock;
