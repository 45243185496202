import React, { useState, useEffect } from 'react';
import styles from '../Main/main.module.css';
import { Submissions } from '../../ui-components';

const FormBlock = (props) => {
    const { setStepState } = props;
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (isLoaded) {
            checkText();
        }
    }, [isLoaded]);

    const checkText = () => {
        const block = document.querySelector('.amplify-storagemanager');
        let content = document.createElement('strong');
        let contentSubtitle = document.createElement('span');
        content.classList.add('dropzone-title');
        content.innerHTML = 'Click to browse or drag and drop your files';
        contentSubtitle.innerHTML = 'Supported formats: JPEG, JPG, PNG, GIF, MP4, PDF, MP3, PPT';
        content.append(contentSubtitle);

        if (block) {
            const elem = block.querySelector('.amplify-storagemanager__dropzone__text');
            if (elem) {
                elem.parentElement.prepend(content);
                elem.remove();
            }
        }
    }

    return (
        <div className={styles.formRoot}>
            <div className="container">
                <h3>Not so social?</h3>
                <p className={styles.paragraph}>You can still join in the fun - Simply fill in the form below!</p>
            </div>
            <div className="container-sm">
                <div className="bg-white">
                    <Submissions
                        onSuccess={() => {
                            setStepState('success');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormBlock;
