/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  CheckboxField,
  Flex,
  Grid,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  fetchByPath,
  getOverrideProps,
  processFile,
  validateField,
} from "./utils";
import { generateClient } from "aws-amplify/api";
import { createSubmissionsForm } from "../graphql/mutations";
import { Field } from "@aws-amplify/ui-react/internal";
import { Amplify } from 'aws-amplify';
import config from '../amplifyconfiguration.json';
Amplify.configure(config);

const client = generateClient();
export default function Submissions(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    postcode: "",
    age_field: false,
    message: "",
    mediafile: undefined,
    terms_and_conditions: false,
    privacy_policy: false,
  };
  const [firstname, setFirstname] = React.useState(initialValues.firstname);
  const [lastname, setLastname] = React.useState(initialValues.lastname);
  const [email, setEmail] = React.useState(initialValues.email);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [postcode, setPostcode] = React.useState(initialValues.postcode);
  const [age_field, setAge_field] = React.useState(initialValues.age_field);
  const [message, setMessage] = React.useState(initialValues.message);
  const [mediafile, setMediafile] = React.useState(initialValues.mediafile);
  const [terms_and_conditions, setTerms_and_conditions] = React.useState(
      initialValues.terms_and_conditions
  );
  const [privacy_policy, setPrivacy_policy] = React.useState(
      initialValues.privacy_policy
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFirstname(initialValues.firstname);
    setLastname(initialValues.lastname);
    setEmail(initialValues.email);
    setPhone(initialValues.phone);
    setPostcode(initialValues.postcode);
    setAge_field(initialValues.age_field);
    setMessage(initialValues.message);
    setMediafile(initialValues.mediafile);
    setTerms_and_conditions(initialValues.terms_and_conditions);
    setPrivacy_policy(initialValues.privacy_policy);
    setErrors({});
  };
  const validations = {
    firstname: [{ type: "Required" }],
    lastname: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    phone: [],
    postcode: [],
    age_field: [],
    message: [],
    mediafile: [],
    terms_and_conditions: [],
    privacy_policy: [],
  };
  const runValidationTasks = async (
      fieldName,
      currentValue,
      getDisplayValue
  ) => {
    const value =
        currentValue && getDisplayValue
            ? getDisplayValue(currentValue)
            : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const termsContent = <span>I agree to the promotion <a href="https://www.aldi.com.au/about-aldi/aldi-competitions/aldicore-competition-terms-conditions/" className="link" target="_blank" rel="noreferrer">T&C’s</a>&nbsp;
    and the website <a href="https://www.aldi.com.au/legal-notice/" className="link" target="_blank" rel="noreferrer">Conditions of Use</a>.</span>;
  const privacyContent = <span>I agree to receive communication about exclusive promotions, offers and products from ALDI and other ALDI brands.&nbsp;
    ALDI will handle my data in accordance with their <a href="https://www.aldi.com.au/privacy-policy/" className="link" target="_blank" rel="noreferrer">Privacy Policy</a>.</span>;

  return (
      <Grid
          as="form"
          rowGap="15px"
          columnGap="15px"
          padding="20px"
          onSubmit={async (event) => {
            event.preventDefault();
            let modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const validationResponses = await Promise.all(
                Object.keys(validations).reduce((promises, fieldName) => {
                  if (Array.isArray(modelFields[fieldName])) {
                    promises.push(
                        ...modelFields[fieldName].map((item) =>
                            runValidationTasks(fieldName, item)
                        )
                    );
                    return promises;
                  }
                  promises.push(
                      runValidationTasks(fieldName, modelFields[fieldName])
                  );
                  return promises;
                }, [])
            );
            if (validationResponses.some((r) => r.hasError)) {
              return;
            }
            if (onSubmit) {
              modelFields = onSubmit(modelFields);
            }
            try {
              Object.entries(modelFields).forEach(([key, value]) => {
                if (typeof value === "string" && value === "") {
                  modelFields[key] = null;
                }
              });
              await client.graphql({
                query: createSubmissionsForm.replaceAll("__typename", ""),
                variables: {
                  input: {
                    ...modelFields,
                  },
                },
              });
              if (onSuccess) {
                onSuccess(modelFields);
              }
              if (clearOnSuccess) {
                resetStateValues();
              }
            } catch (err) {
              if (onError) {
                const messages = err.errors.map((e) => e.message).join("\n");
                onError(modelFields, messages);
              }
            }
          }}
          {...getOverrideProps(overrides, "Submissions")}
          {...rest}
      >
        <Grid
            columnGap="inherit"
            rowGap="inherit"
            templateColumns="repeat(2, auto)"
            {...getOverrideProps(overrides, "RowGrid0")}
        >
          <TextField
              label={
                <span style={{ display: "inline-flex" }}>
              <span>Firstname</span>
              <span style={{ color: "red" }}>*</span>
            </span>
              }
              isRequired={true}
              isReadOnly={false}
              placeholder="John"
              value={firstname}
              maxLength={50}
              onChange={(e) => {
                let { value } = e.target;
                if (onChange) {
                  const modelFields = {
                    firstname: value,
                    lastname,
                    email,
                    phone,
                    postcode,
                    age_field,
                    message,
                    mediafile,
                    terms_and_conditions,
                    privacy_policy,
                  };
                  const result = onChange(modelFields);
                  value = result?.firstname ?? value;
                }
                if (errors.firstname?.hasError) {
                  runValidationTasks("firstname", value);
                }
                setFirstname(value);
              }}
              onBlur={() => runValidationTasks("firstname", firstname)}
              errorMessage={errors.firstname?.errorMessage}
              hasError={errors.firstname?.hasError}
              {...getOverrideProps(overrides, "firstname")}
          ></TextField>
          <TextField
              label={
                <span style={{ display: "inline-flex" }}>
              <span>Lastname</span>
              <span style={{ color: "red" }}>*</span>
            </span>
              }
              isRequired={true}
              isReadOnly={false}
              placeholder="Smith"
              value={lastname}
              maxLength={50}
              onChange={(e) => {
                let { value } = e.target;
                if (onChange) {
                  const modelFields = {
                    firstname,
                    lastname: value,
                    email,
                    phone,
                    postcode,
                    age_field,
                    message,
                    mediafile,
                    terms_and_conditions,
                    privacy_policy,
                  };
                  const result = onChange(modelFields);
                  value = result?.lastname ?? value;
                }
                if (errors.lastname?.hasError) {
                  runValidationTasks("lastname", value);
                }
                setLastname(value);
              }}
              onBlur={() => runValidationTasks("lastname", lastname)}
              errorMessage={errors.lastname?.errorMessage}
              hasError={errors.lastname?.hasError}
              {...getOverrideProps(overrides, "lastname")}
          ></TextField>
        </Grid>
        <TextField
            label={
              <span style={{ display: "inline-flex" }}>
            <span>Email</span>
            <span style={{ color: "red" }}>*</span>
          </span>
            }
            isRequired={true}
            isReadOnly={false}
            placeholder="johnsmith@email.com"
            value={email}
            maxLength={50}
            onChange={(e) => {
              let { value } = e.target;
              if (onChange) {
                const modelFields = {
                  firstname,
                  lastname,
                  email: value,
                  phone,
                  postcode,
                  age_field,
                  message,
                  mediafile,
                  terms_and_conditions,
                  privacy_policy,
                };
                const result = onChange(modelFields);
                value = result?.email ?? value;
              }
              if (errors.email?.hasError) {
                runValidationTasks("email", value);
              }
              setEmail(value);
            }}
            onBlur={() => runValidationTasks("email", email)}
            errorMessage={errors.email?.errorMessage}
            hasError={errors.email?.hasError}
            {...getOverrideProps(overrides, "email")}
        ></TextField>
        <Grid
            columnGap="inherit"
            rowGap="inherit"
            templateColumns="repeat(2, auto)"
            {...getOverrideProps(overrides, "RowGrid2")}
        >
          <TextField
              label="Phone"
              isRequired={false}
              isReadOnly={false}
              placeholder="0430 123 456"
              value={phone}
              maxLength={12}
              type="number"
              onChange={(e) => {
                let { value } = e.target;
                if (value.length > 12) {
                  value = e.target.value.slice(0,12);
                }
                if (onChange) {
                  const modelFields = {
                    firstname,
                    lastname,
                    email,
                    phone: value,
                    postcode,
                    age_field,
                    message,
                    mediafile,
                    terms_and_conditions,
                    privacy_policy,
                  };
                  const result = onChange(modelFields);
                  value = result?.phone ?? value;
                }
                if (errors.phone?.hasError) {
                  runValidationTasks("phone", value);
                }
                setPhone(value);
              }}
              onBlur={() => runValidationTasks("phone", phone)}
              errorMessage={errors.phone?.errorMessage}
              hasError={errors.phone?.hasError}
              {...getOverrideProps(overrides, "phone")}
          ></TextField>
          <TextField
              label="Postcode (Excludes NT & Tas)"
              isRequired={false}
              isReadOnly={false}
              placeholder="3000"
              value={postcode}
              maxLength={4}
              type="number"
              onChange={(e) => {
                let { value } = e.target;
                if (value.length > 4) {
                  value = e.target.value.slice(0,4);
                }
                if (onChange) {
                  const modelFields = {
                    firstname,
                    lastname,
                    email,
                    phone,
                    postcode: value,
                    age_field,
                    message,
                    mediafile,
                    terms_and_conditions,
                    privacy_policy,
                  };
                  const result = onChange(modelFields);
                  value = result?.postcode ?? value;
                }
                if (errors.postcode?.hasError) {
                  runValidationTasks("postcode", value);
                }
                setPostcode(value);
              }}
              onBlur={() => runValidationTasks("postcode", postcode)}
              errorMessage={errors.postcode?.errorMessage}
              hasError={errors.postcode?.hasError}
              {...getOverrideProps(overrides, "postcode")}
          ></TextField>
        </Grid>
        <CheckboxField
            label="I am over 18"
            name="age_field"
            value="age_field"
            isDisabled={false}
            isRequired={true}
            checked={age_field}
            onChange={(e) => {
              let value = e.target.checked;
              if (onChange) {
                const modelFields = {
                  firstname,
                  lastname,
                  email,
                  phone,
                  postcode,
                  age_field: value,
                  message,
                  mediafile,
                  terms_and_conditions,
                  privacy_policy,
                };
                const result = onChange(modelFields);
                value = result?.age_field ?? value;
              }
              if (errors.age_field?.hasError) {
                runValidationTasks("age_field", value);
              }
              setAge_field(value);
            }}
            onBlur={() => runValidationTasks("age_field", age_field)}
            errorMessage={errors.age_field?.errorMessage}
            hasError={errors.age_field?.hasError}
            {...getOverrideProps(overrides, "age_field")}
        ></CheckboxField>
        <TextAreaField
            label="Tell us more about your submission"
            isRequired={false}
            isReadOnly={false}
            maxLength={500}
            placeholder="Start typing..."
            onChange={(e) => {
              let { value } = e.target;
              if (onChange) {
                const modelFields = {
                  firstname,
                  lastname,
                  email,
                  phone,
                  postcode,
                  age_field,
                  message: value,
                  mediafile,
                  terms_and_conditions,
                  privacy_policy,
                };
                const result = onChange(modelFields);
                value = result?.message ?? value;
              }
              if (errors.message?.hasError) {
                runValidationTasks("message", value);
              }
              setMessage(value);
            }}
            onBlur={() => runValidationTasks("message", message)}
            errorMessage={errors.message?.errorMessage}
            hasError={errors.message?.hasError}
            {...getOverrideProps(overrides, "message")}
        ></TextAreaField>
        <Field
            errorMessage={errors.mediafile?.errorMessage}
            hasError={errors.mediafile?.hasError}
            label={"Upload Media"}
            isRequired={false}
            isReadOnly={false}
        >
          <StorageManager
              onUploadSuccess={({ key }) => {
                setMediafile((prev) => {
                  let value = key;
                  if (onChange) {
                    const modelFields = {
                      firstname,
                      lastname,
                      email,
                      phone,
                      postcode,
                      age_field,
                      message,
                      mediafile: value,
                      terms_and_conditions,
                      privacy_policy,
                    };
                    const result = onChange(modelFields);
                    value = result?.mediafile ?? value;
                  }
                  return value;
                });
              }}
              onFileRemove={({ key }) => {
                setMediafile((prev) => {
                  let value = initialValues?.mediafile;
                  if (onChange) {
                    const modelFields = {
                      firstname,
                      lastname,
                      email,
                      phone,
                      postcode,
                      age_field,
                      message,
                      mediafile: value,
                      terms_and_conditions,
                      privacy_policy,
                    };
                    const result = onChange(modelFields);
                    value = result?.mediafile ?? value;
                  }
                  return value;
                });
              }}
              processFile={processFile}
              accessLevel={"public"}
              acceptedFileTypes={[
                ".jpeg",
                ".jpg",
                ".png",
                ".gif",
                ".mp4",
                ".pdf",
                ".mp3",
                ".ppt",
              ]}
              isResumable={false}
              showThumbnails={true}
              maxFileCount={1}
              maxFileSize={150000000}
              {...getOverrideProps(overrides, "mediafile")}
          ></StorageManager>
        </Field>
        <p>Open to Australian residents 18+ (excluding NT & TAS). Ends: 11:59pm AEST on 27/7/24. Max 1 prize p/person. Prize value determined using research from <a href="https://www.canstarblue.com.au/groceries/average-grocery-bill/" target="_blank" rel="noreferrer" className="link">Canstar</a> and <a href="https://www.finder.com.au/budgeting/average-grocery-bill" target="_blank" rel="noreferrer" className="link">Finder</a> and is based on a weekly spend of $300 for a family of 4. Read full Terms and Conditions <a href="https://www.aldi.com.au/about-aldi/aldi-competitions/aldicore-competition-terms-conditions/" target="_blank" rel="noreferrer" className="link">here</a>.</p>
        <CheckboxField
            label={termsContent}
            name="terms_and_conditions"
            value="terms_and_conditions"
            isDisabled={false}
            isRequired={true}
            checked={terms_and_conditions}
            onChange={(e) => {
              let value = e.target.checked;
              if (onChange) {
                const modelFields = {
                  firstname,
                  lastname,
                  email,
                  phone,
                  postcode,
                  age_field,
                  message,
                  mediafile,
                  terms_and_conditions: value,
                  privacy_policy,
                };
                const result = onChange(modelFields);
                value = result?.terms_and_conditions ?? value;
              }
              if (errors.terms_and_conditions?.hasError) {
                runValidationTasks("terms_and_conditions", value);
              }
              setTerms_and_conditions(value);
            }}
            onBlur={() =>
                runValidationTasks("terms_and_conditions", terms_and_conditions)
            }
            errorMessage={errors.terms_and_conditions?.errorMessage}
            hasError={errors.terms_and_conditions?.hasError}
            {...getOverrideProps(overrides, "terms_and_conditions")}
        ></CheckboxField>
        <CheckboxField
            label={privacyContent}
            name="privacy_policy"
            value="privacy_policy"
            isDisabled={false}
            isRequired={true}
            checked={privacy_policy}
            onChange={(e) => {
              let value = e.target.checked;
              if (onChange) {
                const modelFields = {
                  firstname,
                  lastname,
                  email,
                  phone,
                  postcode,
                  age_field,
                  message,
                  mediafile,
                  terms_and_conditions,
                  privacy_policy: value,
                };
                const result = onChange(modelFields);
                value = result?.privacy_policy ?? value;
              }
              if (errors.privacy_policy?.hasError) {
                runValidationTasks("privacy_policy", value);
              }
              setPrivacy_policy(value);
            }}
            onBlur={() => runValidationTasks("privacy_policy", privacy_policy)}
            errorMessage={errors.privacy_policy?.errorMessage}
            hasError={errors.privacy_policy?.hasError}
            {...getOverrideProps(overrides, "privacy_policy")}
        ></CheckboxField>
        <Flex
            justifyContent="space-between"
            {...getOverrideProps(overrides, "CTAFlex")}
        >
          <Flex
              gap="15px"
              {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
          >
            <Button
                children="Submit"
                type="submit"
                variation="primary"
                isDisabled={Object.values(errors).some((e) => e?.hasError)}
                {...getOverrideProps(overrides, "SubmitButton")}
            ></Button>
          </Flex>
        </Flex>
      </Grid>
  );
}
