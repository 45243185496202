/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getSubmissionsForm } from "../graphql/queries";
import { updateSubmissionsForm } from "../graphql/mutations";
const client = generateClient();
export default function SubmissionsFormUpdateForm(props) {
  const {
    id: idProp,
    submissionsForm: submissionsFormModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    postcode: "",
    age_field: false,
    message: "",
    mediafile: "",
    terms_and_conditions: false,
    privacy_policy: false,
  };
  const [firstname, setFirstname] = React.useState(initialValues.firstname);
  const [lastname, setLastname] = React.useState(initialValues.lastname);
  const [email, setEmail] = React.useState(initialValues.email);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [postcode, setPostcode] = React.useState(initialValues.postcode);
  const [age_field, setAge_field] = React.useState(initialValues.age_field);
  const [message, setMessage] = React.useState(initialValues.message);
  const [mediafile, setMediafile] = React.useState(initialValues.mediafile);
  const [terms_and_conditions, setTerms_and_conditions] = React.useState(
    initialValues.terms_and_conditions
  );
  const [privacy_policy, setPrivacy_policy] = React.useState(
    initialValues.privacy_policy
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = submissionsFormRecord
      ? { ...initialValues, ...submissionsFormRecord }
      : initialValues;
    setFirstname(cleanValues.firstname);
    setLastname(cleanValues.lastname);
    setEmail(cleanValues.email);
    setPhone(cleanValues.phone);
    setPostcode(cleanValues.postcode);
    setAge_field(cleanValues.age_field);
    setMessage(cleanValues.message);
    setMediafile(cleanValues.mediafile);
    setTerms_and_conditions(cleanValues.terms_and_conditions);
    setPrivacy_policy(cleanValues.privacy_policy);
    setErrors({});
  };
  const [submissionsFormRecord, setSubmissionsFormRecord] = React.useState(
    submissionsFormModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getSubmissionsForm.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getSubmissionsForm
        : submissionsFormModelProp;
      setSubmissionsFormRecord(record);
    };
    queryData();
  }, [idProp, submissionsFormModelProp]);
  React.useEffect(resetStateValues, [submissionsFormRecord]);
  const validations = {
    firstname: [],
    lastname: [],
    email: [{ type: "Email" }],
    phone: [],
    postcode: [],
    age_field: [],
    message: [],
    mediafile: [],
    terms_and_conditions: [],
    privacy_policy: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstname: firstname ?? null,
          lastname: lastname ?? null,
          email: email ?? null,
          phone: phone ?? null,
          postcode: postcode ?? null,
          age_field: age_field ?? null,
          message: message ?? null,
          mediafile: mediafile ?? null,
          terms_and_conditions: terms_and_conditions ?? null,
          privacy_policy: privacy_policy ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateSubmissionsForm.replaceAll("__typename", ""),
            variables: {
              input: {
                id: submissionsFormRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "SubmissionsFormUpdateForm")}
      {...rest}
    >
      <TextField
        label="Firstname"
        isRequired={false}
        isReadOnly={false}
        value={firstname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname: value,
              lastname,
              email,
              phone,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.firstname ?? value;
          }
          if (errors.firstname?.hasError) {
            runValidationTasks("firstname", value);
          }
          setFirstname(value);
        }}
        onBlur={() => runValidationTasks("firstname", firstname)}
        errorMessage={errors.firstname?.errorMessage}
        hasError={errors.firstname?.hasError}
        {...getOverrideProps(overrides, "firstname")}
      ></TextField>
      <TextField
        label="Lastname"
        isRequired={false}
        isReadOnly={false}
        value={lastname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname: value,
              email,
              phone,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.lastname ?? value;
          }
          if (errors.lastname?.hasError) {
            runValidationTasks("lastname", value);
          }
          setLastname(value);
        }}
        onBlur={() => runValidationTasks("lastname", lastname)}
        errorMessage={errors.lastname?.errorMessage}
        hasError={errors.lastname?.hasError}
        {...getOverrideProps(overrides, "lastname")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email: value,
              phone,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone: value,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="Postcode"
        isRequired={false}
        isReadOnly={false}
        value={postcode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode: value,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.postcode ?? value;
          }
          if (errors.postcode?.hasError) {
            runValidationTasks("postcode", value);
          }
          setPostcode(value);
        }}
        onBlur={() => runValidationTasks("postcode", postcode)}
        errorMessage={errors.postcode?.errorMessage}
        hasError={errors.postcode?.hasError}
        {...getOverrideProps(overrides, "postcode")}
      ></TextField>
      <SwitchField
        label="Age field"
        defaultChecked={false}
        isDisabled={false}
        isChecked={age_field}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode,
              age_field: value,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.age_field ?? value;
          }
          if (errors.age_field?.hasError) {
            runValidationTasks("age_field", value);
          }
          setAge_field(value);
        }}
        onBlur={() => runValidationTasks("age_field", age_field)}
        errorMessage={errors.age_field?.errorMessage}
        hasError={errors.age_field?.hasError}
        {...getOverrideProps(overrides, "age_field")}
      ></SwitchField>
      <TextField
        label="Message"
        isRequired={false}
        isReadOnly={false}
        value={message}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode,
              age_field,
              message: value,
              mediafile,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.message ?? value;
          }
          if (errors.message?.hasError) {
            runValidationTasks("message", value);
          }
          setMessage(value);
        }}
        onBlur={() => runValidationTasks("message", message)}
        errorMessage={errors.message?.errorMessage}
        hasError={errors.message?.hasError}
        {...getOverrideProps(overrides, "message")}
      ></TextField>
      <TextField
        label="Mediafile"
        isRequired={false}
        isReadOnly={false}
        value={mediafile}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode,
              age_field,
              message,
              mediafile: value,
              terms_and_conditions,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.mediafile ?? value;
          }
          if (errors.mediafile?.hasError) {
            runValidationTasks("mediafile", value);
          }
          setMediafile(value);
        }}
        onBlur={() => runValidationTasks("mediafile", mediafile)}
        errorMessage={errors.mediafile?.errorMessage}
        hasError={errors.mediafile?.hasError}
        {...getOverrideProps(overrides, "mediafile")}
      ></TextField>
      <SwitchField
        label="Terms and conditions"
        defaultChecked={false}
        isDisabled={false}
        isChecked={terms_and_conditions}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions: value,
              privacy_policy,
            };
            const result = onChange(modelFields);
            value = result?.terms_and_conditions ?? value;
          }
          if (errors.terms_and_conditions?.hasError) {
            runValidationTasks("terms_and_conditions", value);
          }
          setTerms_and_conditions(value);
        }}
        onBlur={() =>
          runValidationTasks("terms_and_conditions", terms_and_conditions)
        }
        errorMessage={errors.terms_and_conditions?.errorMessage}
        hasError={errors.terms_and_conditions?.hasError}
        {...getOverrideProps(overrides, "terms_and_conditions")}
      ></SwitchField>
      <SwitchField
        label="Privacy policy"
        defaultChecked={false}
        isDisabled={false}
        isChecked={privacy_policy}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              phone,
              postcode,
              age_field,
              message,
              mediafile,
              terms_and_conditions,
              privacy_policy: value,
            };
            const result = onChange(modelFields);
            value = result?.privacy_policy ?? value;
          }
          if (errors.privacy_policy?.hasError) {
            runValidationTasks("privacy_policy", value);
          }
          setPrivacy_policy(value);
        }}
        onBlur={() => runValidationTasks("privacy_policy", privacy_policy)}
        errorMessage={errors.privacy_policy?.errorMessage}
        hasError={errors.privacy_policy?.hasError}
        {...getOverrideProps(overrides, "privacy_policy")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || submissionsFormModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || submissionsFormModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
