import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { studioTheme } from "./ui-components";

const updatedTheme = createTheme({
    // Extend the theme to update the button color
    name: "my-theme-updates",
    tokens: {
        fonts: {
            default: {
                variable: { value: 'Open Sans, sans-serif' },
                static: { value: 'Open Sans, sans-serif' },
            },
        },
    },
}, studioTheme);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <ThemeProvider theme={updatedTheme}>
              <App />
          </ThemeProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
