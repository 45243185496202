import React, {useEffect, useState, useRef} from 'react';
import styles from '../Main/main.module.css';
import Copy from "../../img/copy.svg";
import copy from "copy-to-clipboard";
const URL_TO_COPY = 'https://www.aldicore.com.au';

const CopyBlock = () => {
    const ref = useRef(null);
    const [openedTooltip, setOpenedTooltip] = useState(false);

    useEffect(() => {
        if (openedTooltip) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openedTooltip]);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenedTooltip(false);
        }
    }

    const copyToClipboard = (e) => {
        e.preventDefault();
        const input = document.getElementById('link_input');
        let value = !input.value.length ? URL_TO_COPY : input.value;
        if(!openedTooltip) {
            setOpenedTooltip(!openedTooltip);
        }

        copy(value);
    }

    return (
        <form>
            <div className={styles.field}>
                <input className={styles.input} id="link_input" placeholder={URL_TO_COPY} type="text"/>
                <button onClick={copyToClipboard} ref={ref}>
                    <img src={Copy} alt="copy" />
                    <div id="copy_tooltip" className={styles.tooltip} style={{display: openedTooltip ? 'block' : 'none'}}>
                        <span>Website address copied</span>
                    </div>
                </button>
            </div>
        </form>
    );
};

export default CopyBlock;
